$(document).ready(function () {
    $('[data-toggle="tooltip"]').tooltip();
    $(document).on('click', '[data-toggle="lightbox"]', function(event) {
        event.preventDefault();
        $(this).ekkoLightbox({
            alwaysShowClose: true
        });
    });
    $('.fadeIn').fadeIn(500);
    $('#main-container, .termsBox').overlayScrollbars({
        scrollbars : {
            dragScrolling: true,
            clickScrolling: true,
            touchSupport: true,
            snapHandle: true
        }
    });

    // ----floatin input label in input and select
    $('.form-group').find('.floating-control').each(function (index, ele) {
        let $ele = $(ele);
        if($ele.val() != '' || $ele.is(':selected') === true){
            $ele.parents('.floating-group').addClass('focused');
        }
    });
    $('.floating-control').on('focus', function (e) {
        $(this).parents('.floating-group').addClass('focused');
    }).on('blur', function(){
        if($(this).val().length > 0){
            $(this).parents('.floating-group').addClass('focused');
        }else{
            $(this).parents('.floating-group').removeClass('focused');
        }
    });
    $('.floating-control').on('change', function (e) {
        if($(this).is('select')){
            if($(this).val() === $("option:first", $(this)).val()) {
                $(this).parents('.floating-group').removeClass('focused');
            }else{
                $(this).parents('.floating-group').addClass('focused');
            }
        }
    });
    //---- select2 single----
    $('.customSelect').each(function() {
        let dropdownParents = $(this).parents('.select2Part');
        $(this).select2({
            dropdownParent: dropdownParents,
            minimumResultsForSearch: -1
        }).on("select2:open", function (e) {
            $('.select2-results').overlayScrollbars({ });
            $(this).parents('.floating-group').addClass('focused');
        }).on("select2:close", function (e) {
            if($(this).find(':selected').val() === ''){
                $(this).parents('.floating-group').removeClass('focused');
            }
        });
    });
    //---- select2 multiple----
    $('.customSelectMultiple').each(function() {
        let dropdownParents = $(this).parents('.select2Part');
        $(this).select2({
            dropdownParent: dropdownParents,
            closeOnSelect: false
        }).on("select2:open", function (e) {
            $('.select2-results').overlayScrollbars({ });
            $(this).parents('.floating-group').addClass('focused');
        }).on("select2:close", function (e) {
            if($(this).val() != ''){
                $(this).parents('.floating-group').addClass('focused');
            }else{
                $(this).parents('.floating-group').removeClass('focused');
            }
        }).on("select2:select", function (e) {
            $(this).parents('.floating-group').addClass('focused');
        }).on("select2:unselect", function (e) {
            $(this).parents('.floating-group').addClass('focused');
        });
    });

    $('.transactionTableDesign').DataTable({
        "searching": false,
        "paging": true,
        "lengthChange": false,
        "info": false,
        "order": false,
        "pageLength": 5,
        "language": {
            "paginate": {
                "next": "<i class='icon-arrow-right-filled'></i>",
                "previous": "<i class='icon-arrow-left-filled'></i>"
            }
        },
        columnDefs: [
            { targets: 'no-sort', orderable: false }
        ]
    });

    $('.defaultTableDesign').DataTable({
        "searching": true,
        "paging": true,
        "info": true,
        "language": {
            "paginate": {
                "next": "<i class='icon-arrow-right-filled'></i>",
                "previous": "<i class='icon-arrow-left-filled'></i>"
            }
        },
        columnDefs: [
            { targets: 'no-sort', orderable: false }
        ]
    });

    $('#changeTermsLangDe').on('click', function () {
        $('.termsEng').removeClass('show');
        $('.termsDe').addClass('show');
    });

    $('#changeTermsLangEn').on('click', function () {
        $('.termsDe').removeClass('show');
        $('.termsEng').addClass('show');
    });

    $('#acceptDe, #acceptEn').on('input', function () {
        if(this.checked){
            $('.undisable').removeAttr('disabled');
        }else{
            $('.undisable').attr('disabled', true);
        }
    });

    let startLoadingPings = $('.start-loading-ping');
    if(startLoadingPings.length) {
        startLoadingPings.each(function() {
            let runner1 = 1;
            let runner2 = 0;
            poll($(this), runner1, runner2);
        });
    }
    function poll(stack, runner1, runner2) {
        setTimeout(function () {
            GetData(stack, runner1, runner2);
        }, 6000);
    }

    function GetData(stack, runner1, runner2) {
        $.ajax({
            url: "/environment/status/"+stack.data('env'),
            type: "GET",
            dataType: "json",
            success: function(resultData) {
                if(resultData.credentials !== 'none' && resultData.credentials !== 'copied'){
                    stack.parent().children('.open-cred-modal').attr('data-credentials', resultData.credentials);
                    stack.parent().children('.open-cred-modal').show();
                    stack.closest('.container').find('.env-top-nav').find('.open-cred-modal').attr('data-credentials', resultData.credentials);
                    stack.closest('.container').find('.env-top-nav li.hidden').removeClass('hidden');
                }
                if(resultData.status === 'starting' || resultData.status === 'extending'){
                    stack.parent().children('.badge-building').hide();
                    stack.parent().children('.badge-starting').show();
                    let $elems = stack.parent().children('.badge-starting').find('.addv');
                    $elems.removeClass('active').eq(runner2++ % $elems.length).addClass('active').css('opacity', 0).animate({'opacity': 1});
                    const timeText = resultData.status === 'starting' ? resultData.created : resultData.updated;
                    stack.parent().children('.badge-timer').find('.timeSinceCreated').text(timeText);
                    poll(stack, runner1, runner2);
                }
                if(resultData.status === 'loading'){
                    stack.parent().children('.badge-building').show();
                    stack.parent().children('.badge-starting').hide();
                    let $elems = stack.parent().children('.badge-building').find('.addv');
                    $elems.removeClass('active').eq(runner1++ % $elems.length).addClass('active').css('opacity', 0).animate({'opacity': 1});
                    stack.parent().children('.badge-timer').find('.timeSinceCreated').text(resultData.created);
                    poll(stack, runner1, runner2);
                }
                if(resultData.status === 'error' || resultData.status === 'removed' || resultData.extend_error){
                    stack.hide();
                    stack.parent().children('.badge-building').hide();
                    stack.parent().children('.badge-starting').hide();
                    $('.env-creation-error').css('display', 'flex');
                }
                if(resultData.status === 'online' && !resultData.extend_error){
                    let url = window.location.href;
                    if (url.indexOf('?') > -1){
                        url += '&m=congrats'
                    }else {
                        url += '?m=congrats'
                    }
                    window.location.href = url;
                }
            },
            error : function(xhr, textStatus, errorThrown) {
                //...
            },
            timeout: 0,
        });
    }

    $('.openAppVideoModal').on('click', function (e) {
        e.preventDefault();
        $('iframe.embed-responsive-item').attr('src', $(this).data('src'));
        $('#appVideoModal').modal('toggle');
    });
    $('#appVideoModal').on('hidden.bs.modal', function () {
        $('iframe.embed-responsive-item').attr('src', '');
    });

    $('.user-menu').on('hidden.bs.dropdown', function () {
        $(this).find('i').removeClass('icon-human-active');
        $(this).find('i').addClass('icon-human-inactive');
    });

    $('.user-menu a').on('click', function () {
            $(this).find('i').removeClass('icon-human-inactive');
            $(this).find('i').addClass('icon-human-active');
    });

    $('.env-settings, .micro-nav').on('hidden.bs.dropdown', function () {
        $(this).find('i').removeClass('icon-more-active');
        $(this).find('i').addClass('icon-more-inactive');
    }).find('a').on('click', function () {
        $(this).find('i').removeClass('icon-more-inactive');
        $(this).find('i').addClass('icon-more-active');
    });

    $('.app-settings').on('hidden.bs.dropdown', function () {
        $(this).find('i').removeClass('icon-setting-filled');
        $(this).find('i').addClass('icon-setting-outline');
    }).find('a').on('click', function () {
        $(this).find('i').removeClass('icon-setting-outline');
        $(this).find('i').addClass('icon-setting-filled');
    });

    $('.play-env a, .micro-nav a.play').on('mouseenter', function () {
        $(this).find('i').removeClass('icon-play-inactive');
        $(this).find('i').addClass('icon-play-filled');
    }).on('mouseleave', function (){
        $(this).find('i').removeClass('icon-play-filled');
        $(this).find('i').addClass('icon-play-inactive');
    });

    $('.env-alerts').on('hidden.bs.dropdown', function () {
        $(this).find('i').removeClass('icon-notification-active');
        $(this).find('i').addClass('icon-notification-inactive');
    });

    $('.env-alerts a').on('click', function () {
        $(this).find('i').removeClass('icon-notification-inactive');
        $(this).find('i').addClass('icon-notification-active');
    });
    $('.env-alerts i').trigger('click');

    $('.termsContainer').animate({ top : '100px' }, 150);

    $('.openTerms').on('click', function (e) {
        e.preventDefault();
        if($("#terms").css('height') === '0px'){
            $("#terms").animate({height: '90%', paddingTop: '50px'}, 150);
        }else{
            $("#terms").animate({height: '0px', paddingTop: '0px'}, 150);
        }
    });

    $('.openFeedback').on('click', function (){
        $('#reviewRatingModal').modal('show');
    })

    $('#openAppImagesModal').on('click', function (e) {
        $('#appImagesModal').modal({ backdrop: true });
        $('.img-selection-container a').on('click', function (e) {
            e.preventDefault();
            let that = $(this);
            $('#appimages_url').val(that.data('src'));
            $('.img-selection-container a img').removeClass('active');
            that.find('img').addClass('active');
            $('#appImagesModal').find('.save-img').on('click', function (e) {
                $('#preview_app_images').attr('src', window.location.protocol + "//" + window.location.host + "/" + that.data('src'));
                $('#appImagesModal').modal('hide');
            });
        })
    });

    $('#openAppIconModal').on('click', function (e) {
        $('#appIconModal').modal({ backdrop: true });
        $('.icon-selection-container a').on('click', function (e) {
            e.preventDefault();
            let that = $(this);
            $('#appicon_url').val(that.data('src'));
            $('.icon-selection-container a img').removeClass('active');
            that.find('img').addClass('active');
            $('#appIconModal').find('.save-img').on('click', function (e) {
                $('#preview_app_icon').attr('src', window.location.protocol + "//" + window.location.host + "/" + that.data('src'));
                $('#appIconModal').modal('hide');
            });
        })
    });

    $('#btn-sendFeedback').on('click', function (e) {
        $('#preloader').show();
        $.ajax({
            type: "POST",
            url: "/profile/feedback",
            data: {
                _token: $('#csrf-token').attr('content'),
                rating: $('input[name="rating"]:checked').val(),
                message: $('#review_message').val()
            },
            success: function(data) {
                $('#preloader').hide();
                if(data.status === 'success'){
                    $('.review-item').fadeOut(500, function (){
                        $('.review-checkout').fadeIn(500);
                    });
                }else{
                    $('#review_label').text(data.messages.message[0]);
                    $('#review_label').css({'color': '#f06464'});
                    $('#review_message').addClass('is-invalid');
                }
            },
            error: function(xhr, status, error){
                console.error(xhr);
            }
        });
    });

    $('.subscribe').on('click', function (e) {
        $('#price_id').val($(this).data('price'));
        $('#subscribeModal').modal({ backdrop: true });
        /* Phone is optional: redo by add disabled attribute on app detail templates submit button
        $('#c-phone').on('input', function(e){
            if(parseInt($(this).val().replace(/[^0-9\.]/g, ''), 10).toString().length > 7){
                $('.submit-subscription').removeAttr('disabled');
            }else{
                $('.submit-subscription').attr('disabled', true);
            }
        });
         */
    });

    // Unbind any previous event handlers to avoid issues
    $('.open-del-modal').off('click').on('click', function(e) {
        e.preventDefault();

        // Cache frequently used elements for better performance
        const envModalDelete = $('#envModalDelete');
        const inputName = $('#inputName');
        const deleteEnv = $('.deleteEnv');

        // Get data attributes
        const name = $(this).data('name');
        const env_id = $(this).data('id');

        // Update modal content
        $('.copy-code-env').text(name);

        // Handle input changes
        inputName.off('input').on('input', function(e) {
            if ($(this).val() === name) {
                deleteEnv.removeAttr('disabled').off('click').one('click', function(e) {
                    $('#preloader').show();
                    $.ajax({
                        type: 'POST',
                        url: '/apps/remove/environment',
                        data: {
                            _token: $('#csrf-token').attr('content'),
                            env_id: env_id
                        },
                        success: function(data) {
                            // Hide the modal and navigate to '/home'
                            envModalDelete.modal('hide');
                            location.assign('/home');
                        },
                        error: function(xhr, status, error) {
                            console.error(xhr);
                        }
                    });
                });
            } else {
                deleteEnv.attr('disabled', true);
            }
        });

        // Show the modal with backdrop
        envModalDelete.modal({ backdrop: true });
    });

    $('.open-reduce-modal').on('click', function (e) {
        e.preventDefault();
        $('#reduce_app_id').val($(this).data('id'));
        $('#envModalReduce').find('#appName').text($(this).data('name'));
        $('#envModalReduce').modal({ backdrop: true });
    });

    $('.open-rename-modal').on('click', function (e) {
        e.preventDefault();
        $('#envModalRename').modal({ backdrop: true }).show();
        let headline = $(this).closest('.e1-card-head').find('.headline_env');
        let env_id = $(this).data('id');
        $('#inputRename').val(headline.text());
        $('#inputRename').on('input', function(e){
            if($(this).val().replace(/^\s+|\s+$/g, "").length === 0) {
                $('.renameEnv').attr('disabled', true);
            }else{
                $('.renameEnv').removeAttr('disabled');
            }
        });
        $('.renameEnv').on('click', function (e) {
            $('#preloader').show();
            $.ajax({
                type: "POST",
                url: "/apps/rename/environment",
                data: {
                    _token: $('#csrf-token').attr('content'),
                    env_id: env_id,
                    env_name: $('#inputRename').val()
                },
                success: function(data) {
                    //$('#envModalRename').modal('hide');
                    //headline.text(data.name);
                    //$('#preloader').hide();
                    location.assign('/home');
                },
                error: function(xhr, status, error){
                    console.error(xhr);
                }
            });
        });

    });

    $('.openTicketModal').on('click', function (e) {
        let modal = $('#ticketDetailModal');
        let issue_id = $(this).data('id');
        modal.find('.issue, .comments').html(null);
        modal.find('textarea').val(null);
        modal.find('#submit').attr('disabled', true);
        modal.find('#submit').data('id', null);
        $('#preloader').show();
        $.ajax({
            type: "POST",
            url: "/support/get-ticket",
            data: {
                _token: $('#csrf-token').attr('content'),
                issue_id: issue_id
            },
            success: function(data) {
                let issueOutput = '';
                let commentsOutput = '';
                $('#preloader').hide();
                issueOutput += '<ul class="list-inline mb-3">';
                switch (data.issue.currentStatus.status) {
                    case 'Open':
                        issueOutput += '<li class="list-inline-item"><span class="badge badge-green" data-toggle="tooltip" data-placement="top" title="Status"><i class="icon-edit-inactive"></i> Open</span></li>';
                        break;
                    case 'Closed':
                        issueOutput += '<li class="list-inline-item"><span class="badge badge-grey" data-toggle="tooltip" data-placement="top" title="Status"><i class="icon-lock-active"></i> Closed</span></li>';
                        break;
                }
                issueOutput += '<li class="list-inline-item"><span class="badge badge-info" data-toggle="tooltip" data-placement="top" title="Open Service Desk"><i class="icon-info-inactive"></i><a href="'+ data.issue._links.web +'"  target="_blank">'+ data.issue.issueKey +'</a></span></li>';
                issueOutput += '<li class="list-inline-item"><span class="badge badge-info" data-toggle="tooltip" data-placement="top" title="Application"><i class="icon-product-inactive"></i>'+ data.issue.requestFieldValues[2].value.value +'</span></li>';
                issueOutput += '<li class="list-inline-item"><span class="badge badge-info" data-toggle="tooltip" data-placement="top" title="Created"><i class="icon-calendar-inactive"></i>'+ moment(data.issue.createdDate.epochMillis).format('DD.MM.YYYY HH:mm') +'</span></li>';
                issueOutput += '</ul>';
                issueOutput += '<div class="border-e1 border p-2 mb-2"><a href="'+ data.issue._links.web +'" target="_blank" class="float-right"><i class="icon-link-inactive"></i></a><h5 class="mb-3">'+ data.issue.requestFieldValues[0].value +'</h5>';
                issueOutput += '<p>'+ data.issue.requestFieldValues[1].value +'</p></div>';
                $.each(data.comments.values, function (key, val) {
                    let name = val.author.displayName;
                    if(name === "meta-bot@germanedge.com"){
                        name = data.user.name;
                    }
                    if(val.body.indexOf('|thumbnail!') < 1){
                        commentsOutput += '<div class="m-1">'+ name +'</div>';
                        commentsOutput += '<blockquote class="mt-1">'+ val.body +'</blockquote>';
                    }
                });
                if(commentsOutput === ''){
                    $('.commentsheadline').hide();
                }else{
                    $('.commentsheadline').show();
                }
                modal.find('.issue').append(issueOutput);
                modal.find('.comments').append(commentsOutput);
                modal.find('#submit').data('id', issue_id);
                $('[data-toggle="tooltip"]').tooltip();
                modal.modal({ backdrop: true }).show();
                modal.find('textarea').unbind('input').on('input', function () {
                    if($(this).val().replace(/^\s+|\s+$/g, "").length === 0) {
                        modal.find('#submit').attr('disabled', true);
                    }else{
                        modal.find('#submit').removeAttr('disabled');
                    }
                });
                modal.find('#submit').unbind('click').on('click', function () {
                    $('#preloader').show();
                    $.ajax({
                        type: "POST",
                        url: "/support/send-ticket-comment",
                        data: {
                            _token: $('#csrf-token').attr('content'),
                            issue_id: $(this).data('id'),
                            text: modal.find('textarea').val()
                        },
                        success: function(response) {
                            $('.commentsheadline').show();
                            modal.find('textarea').val(null);
                            modal.find('#submit').attr('disabled', true);
                            let name = response.author.displayName;
                            if(name === "meta-bot@germanedge.com"){
                                name = data.user.name;
                            }
                            modal.find('.comments').append('<div class="m-1">'+ name +'</div>');
                            modal.find('.comments').append('<blockquote class="mt-1">'+ response.body +'</blockquote>');
                            $('#preloader').hide();
                        },
                        error: function(xhr, status, error){
                            console.error(xhr);
                        }
                    });
                });
            },
            error: function(xhr, status, error){
                console.error(xhr);
            }
        });

    });

    $('.open-cred-modal').on('click', function (e) {
        $('#envModalCred').modal({ backdrop: true });
        //main.addClass('blur-filter');
        let cred = $(this).data('credentials');
        let env_id = $(this).data('env');
        $('#envModalCred').find('.copy-username').val(cred.user);
        $('#envModalCred').find('.copy-pwd').val(cred.password);
        $('#acceptPwdDelete').change(function(e){
            if(this.checked){
                $('.deleteCred').removeAttr('disabled');
                $('.deleteCred').off('click').on('click', function (e) {
                    $('#preloader').show();
                    $.ajax({
                        type: "POST",
                        url: "/apps/remove/credentials",
                        data: {
                            _token: $('#csrf-token').attr('content'),
                            env_id: env_id
                        },
                        success: function(data) {
                            $('#envModalCred').modal('hide');
                            location.reload();
                        },
                        error: function(xhr, status, error){
                            console.error(xhr);
                        }
                    });
                });
            }else{
                $('.deleteCred').attr('disabled', true);
            }
        });
    });

    $('.showPreloader').on('click', function (e) {
        $('#preloader').show();
    });

    $('.close-alert').on('click', function (e) {
        $(this).closest('.alert-topbar, .alert-topbar-static').fadeOut();
    });

    if(typeof($('.alert-topbar')[0]) != "undefined" && $('.alert-topbar')[0] !== null) {
        setTimeout(function(){
            $(".close-alert").trigger("click");
        }, 6000);
    }

    $('.breadcrumb-toggle').on('click', function (e){
        e.preventDefault();
        e.stopPropagation();
        let that = $(this);
        let items = $('.breadcrumb-item');
        if(items.is(':visible')){
            $.ajax({
                type: "POST",
                url: "/breadcrumb/store",
                data: {
                    _token: $('#csrf-token').attr('content'),
                    collapsed: 1
                },
                success: function(data) {
                    items.addClass('hide');
                    that.find('i').removeClass('icon-breadcrumb-filled');
                    that.find('i').addClass('icon-breadcrumb-outline');
                },
                error: function(xhr, status, error){
                    console.error(xhr);
                }
            });
        }else{
            $.ajax({
                type: "POST",
                url: "/breadcrumb/store",
                data: {
                    _token: $('#csrf-token').attr('content'),
                    collapsed: 0
                },
                success: function(data) {
                    items.removeClass('hide');
                    that.find('i').removeClass('icon-breadcrumb-outline');
                    that.find('i').addClass('icon-breadcrumb-filled');
                },
                error: function(xhr, status, error){
                    console.error(xhr);
                }
            });
        }
    });

    $('#search').typeahead({
        minLength: 3,
        source: function (query, process) {
            return $.get('/autocomplete/apps', {
                query: query
            }, function (data) {
                return process(data);
            });
        }
    });

    $('input.money-format').inputmask();
    $('input.phone-format').inputmask("+99 999 999 [9][9][9] [9][9][9]");
    $('input.plz-format').inputmask("9999[9][9][9]");
    $('#inputRename').inputmask({ regex: "^[A-Za-z0-9]+([-_.+!*]+[A-Za-z0-9]+)*$" });
});
