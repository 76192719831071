require('./bootstrap');
require('admin-lte');
require('admin-lte/plugins/datatables-bs4/js/dataTables.bootstrap4');
require('admin-lte/plugins/overlayScrollbars/js/OverlayScrollbars');
require('admin-lte/plugins/select2/js/select2');
require('admin-lte/plugins/inputmask/jquery.inputmask.min');
require('admin-lte/plugins/bootstrap-switch/js/bootstrap-switch.min');
require('admin-lte/plugins/chart.js/Chart.min');
require('admin-lte/plugins/toastr/toastr.min');
require('admin-lte/plugins/ekko-lightbox/ekko-lightbox.min');
require('bootstrap-3-typeahead/bootstrap3-typeahead.min');
require('./custom');
